import { Box, Container } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import InfoCard from "./InfoCard";
import { IEquipment, IStoreValue } from "utils/types";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import greenDotIcon from "assets/images/GPS/rectangle-marker-grn.png";
import orangeDotIcon from "assets/images/GPS/rectangle-marker-org.png";
import clusterIconGreen from "assets/images/GPS/cluster-icon-green.png";
import clusterIconOrange from "assets/images/GPS/cluster-icon-orange.png";
import CurrenyFormat from "components/common/CurrenyFormat";
import { useSelector, useDispatch } from "react-redux";
import { EquipmentAPIs } from "service/api-service";
import {
  setLoginModalOpen,
  setalertMessage,
  setalertMessagetype,
  setSessionExpire,
  setEquipments,
} from "redux/redux-slice";
import filledheartIcon from "../../../assets/images/liked.png";
import emptyheartIcon from "../../../assets/images/not-liked.png";
const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;

interface MarkerKeys {
  lat: number;
  lng: number;
  id: number;
  price_day: string;
  title: string;
  userId?: string;
  equipListType?: string;
}
const proximityThreshold = 0.05;
const MapResult = ({ equipments }: { equipments: IEquipment[] }) => {
  const dispatch = useDispatch();
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: APIKEY,
  });
  const mapview = useRef(null);
  const [map, setMap] = useState<google.maps.Map | null>(null); // Explicitly typed
  const [markerLocation, setMarkerLocation] = useState<Array<MarkerKeys[]>>([]);
  const [centerLocation, setCenterLocation] = useState<MarkerKeys | null>(null);
  const [filterEquipment, setFilterEquipment] = useState<IEquipment[]>([]);

  const [activeMarker, setActiveMarker] = useState<Array<number>>([]);
  const [zoomLevel, setZoomLevel] = useState<number>(10);

  const fitMapBound = () => {
    setTimeout(() => {
      if (map) {
        const bounds = new window.google.maps.LatLngBounds();
        let validGps = 0;
        markerLocation.forEach((group) => {
          const { lat, lng } = group[0];
          if (lat && lng) {
            bounds.extend({ lat, lng });
            validGps++;
          }
        });
        if (validGps > 1) {
          map.fitBounds(bounds); // Fit bounds to include all markers
        }
      }
    }, 500);
  };

  const handleElementLike = (
    element: HTMLImageElement,
    likeStatus: string,
    listOfClasses: unknown[]
  ) => {
    if (likeStatus === "0") {
      element.src = emptyheartIcon;
      element.classList.remove("liked");
    } else if (!listOfClasses?.includes("liked")) {
      element.classList.add("liked");
      element.src = filledheartIcon;
    }
  };

  const handleLikeEquipments = useCallback(
    async ({
      element,
      equipId,
      likeType,
      listOfClasses,
    }: {
      element: HTMLImageElement;
      equipId: number;
      likeType: string;
      listOfClasses: unknown[];
    }) => {
      try {
        if (!isLoggedin) {
          dispatch(setLoginModalOpen(true));
          return false;
        }
        const likeStatus = listOfClasses?.includes("liked") ? "0" : "1";
        handleElementLike(element, likeStatus, listOfClasses);
        // Make the API call to AddToLikeDislike
        const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
        const response = await EquipmentAPIs.AddToLikeDislike(
          {
            user_id: AuthUserId.toString(),
            equip_id: equipId.toString(),
            status: likeStatus,
            liked_equip_list_type: likeType,
          },
          headers
        );

        // Check the type of response received
        if (response["type"] == "RXSUCCESS") {
          console.log("success");
        } else if (
          response["status"] == false &&
          response["slug"] == "Session Expired"
        ) {
          const likeStatus = listOfClasses?.includes("liked") ? "1" : "0";
          handleElementLike(element, likeStatus, listOfClasses);
          dispatch(setSessionExpire(true));
        } else {
          throw new Error(response["message"]);
        }
      } catch (err) {
        const likeStatus = listOfClasses?.includes("liked") ? "1" : "0";
        handleElementLike(element, likeStatus, listOfClasses);
        dispatch(setalertMessage(err.message));
        dispatch(setalertMessagetype("error"));
      }
    },
    []
  );

  const handleMarkerClick = useCallback((event, location: MarkerKeys) => {
    if (
      event.target instanceof HTMLImageElement &&
      event.target.tagName === "IMG"
    ) {
      const listOfClasses = Array.from(event.target.classList);
      if (listOfClasses?.includes("likeIcon")) {
        handleLikeEquipments({
          element: event.target,
          equipId: location?.id,
          likeType: location?.equipListType,
          listOfClasses,
        });
      } else {
        window.open(
          `/detail/${location?.userId}/${location?.id}/${location?.equipListType}`,
          "_blank"
        );
      }
      return;
    }
    if ("latLng" in event) {
      handleClusterClick([location]);
    } else {
      setCenterLocation(location);
      setActiveMarker([location?.id]);
      if (map) {
        map.setZoom(12); // Ensure the map object is valid
        map.panTo(location);
      }
    }
  }, []);

  const handleClusterClick = useCallback((groups: MarkerKeys[]) => {
    const ids = groups.map(({ id }) => id);
    const topOfEquip = [];
    const restOfEquip = [];
    equipments.forEach((equip) => {
      if (ids?.includes(equip?.id)) {
        topOfEquip.push(equip);
      } else {
        restOfEquip.push(equip);
      }
    });
    const modifiedEquip = [...topOfEquip, ...restOfEquip];
    setFilterEquipment(modifiedEquip);
    setActiveMarker(ids);
  }, []);

  const debounce = (func, wait) => {
    let timeout;
    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(), wait);
    };
  };

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const arePointsNear = (point1, point2, threshold) => {
    return (
      Math.abs(point1.lat - point2.lat) <= threshold &&
      Math.abs(point1.lng - point2.lng) <= threshold
    );
  };

  // Group locations by proximity
  const groupLocationsByProximity = (locations, threshold) => {
    const groups = [];
    const visited = new Set();

    locations.forEach((location, index: number) => {
      if (visited.has(index)) return;

      const group = [location];
      visited.add(index);

      for (let j = index + 1; j < locations.length; j++) {
        if (visited.has(j)) continue;

        const otherLocation = locations[j];
        if (arePointsNear(location, otherLocation, threshold)) {
          group.push(otherLocation);
          visited.add(j);
        }
      }

      groups.push(group);
    });

    return groups;
  };

  const calculateProximityThreshold = (zoomLevel) => {
    if (zoomLevel > 13) return 0.001;
    if (zoomLevel >= 6) return 0.01;
    const baseThreshold = 0.05; // Adjust this value as needed
    return baseThreshold / Math.pow(2, zoomLevel);
  };

  const onMapLoad = (map) => {
    setMap(map);
    const handleZoomChange = debounce(() => {
      console.log("called debound");
      const newZoomLevel = map.getZoom();
      const equipLatLng = markerLocation.flat();
      if (equipLatLng?.length > 0) {
        // map.setZoom(newZoomLevel);
        const Threshold = calculateProximityThreshold(newZoomLevel);
        const clusters = groupLocationsByProximity(equipLatLng, Threshold);
        setMarkerLocation(clusters);
      }
    }, 500);

    map.addListener("zoom_changed", handleZoomChange);
  };

  useEffect(() => {
    const equipLatLng = equipments.map(
      ({
        lat,
        lng,
        id,
        price_day,
        make,
        equip_sale_price,
        equip_list_type,
      }: IEquipment) => ({
        lat,
        lng,
        id,
        price_day: equip_list_type === "rent" ? price_day : equip_sale_price,
        title: make,
      })
    );
    setCenterLocation(equipLatLng[0]);
    setActiveMarker([equipLatLng[0]?.id]);
    const clusters = groupLocationsByProximity(equipLatLng, proximityThreshold);
    setMarkerLocation(clusters);
    setFilterEquipment(equipments);
    // if (clusters?.length && map) {
    //   fitMapBound();
    // }
  }, [equipments]);

  // useEffect(() => {
  //   if (markerLocation.length && map) {
  //     fitMapBound();
  //   }
  // }, [markerLocation, map]);

  return (
    <Container>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            sm: "1fr",
            md: "repeat(2, 1fr)",
            gap: "2rem",
          },
          alignItems: "flex-start",
          mb: "3rem",
          mt: "2rem",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {filterEquipment.map((equip: IEquipment) => {
            const isBorder = activeMarker?.includes(equip.id);
            return (
              <Box
                key={equip.id}
                onClick={(event) =>
                  handleMarkerClick(event, {
                    id: equip.id,
                    price_day: equip.price_day,
                    lat: equip?.lat,
                    lng: equip?.lng,
                    title: equip?.make,
                    userId: equip?.user_id,
                    equipListType: equip?.equip_list_type,
                  })
                }
                sx={{ cursor: "pointer" }}
              >
                <InfoCard
                  pricePerDay={equip.price_day || 0}
                  pricePerMonth={equip.price_month || 0}
                  pricePerWeek={equip.price_per_week || 0}
                  equipTitle={equip.make || "N/A"}
                  equipModal={equip.model || "N/A"}
                  equipLocation={`${
                    !equip.city && !equip.state
                      ? "N/A"
                      : !equip.city
                      ? "N/A, " + equip.state
                      : equip.city + ", " + equip.state
                  }`}
                  thumbnail={equip.thumbnail}
                  isBorder={isBorder}
                  likeStatus={equip?.like_status}
                  equipListType={equip?.equip_list_type}
                  salePrice={equip?.equip_sale_price || 0}
                  rpoAvailable={equip?.rpo_available}
                  obo={equip?.obo}
                />
              </Box>
            );
          })}
        </Box>
        <Box sx={{ position: { xs: "unset", md: "sticky" }, top: "8rem" }}>
          <Box
            sx={{ width: "100%", height: "450px", backgroundColor: "white" }}
          >
            {isLoaded ? (
              <GoogleMap
                onLoad={onMapLoad}
                ref={mapview}
                center={centerLocation}
                zoom={10}
                options={{
                  minZoom: 3, // Set the minimum zoom level
                  maxZoom: 20, // Optionally set a maximum zoom level
                }}
                mapContainerStyle={{
                  width: "100%",
                  height: "100%",
                }}
                onUnmount={onUnmount}
              >
                {markerLocation.map((group, index) => {
                  const firstLocation = group[0];
                  const isCluster = group.length > 1;
                  const length =
                    group.length === 1
                      ? firstLocation?.price_day?.toString()?.length
                      : 3;
                  const width = isCluster
                    ? 50
                    : Math.floor(
                        length > 8
                          ? length * 15
                          : length <= 3
                          ? 60
                          : length * 18
                      );
                  const centerWidth = isCluster ? 24 : Math.floor(width / 2);
                  const label = isCluster
                    ? {
                        text: `${group.length}`, // Number of points in the cluster
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }
                    : {
                        text: `$${new Intl.NumberFormat("en-US", {}).format(
                          parseFloat(firstLocation?.price_day) || 0
                        )}`,
                        color: "#fff",
                        fontWeight: "bold",
                      };

                  const markerLength = isCluster ? 50 : 30;
                  const markerCenterLength = isCluster ? 24 : 12;

                  const activeCluster =
                    isCluster &&
                    group.some(({ id }) => activeMarker.includes(id));

                  return (
                    <Marker
                      key={index}
                      position={{
                        lat: firstLocation.lat,
                        lng: firstLocation.lng,
                      }}
                      title={isCluster ? "" : firstLocation?.title}
                      label={label}
                      icon={{
                        url: activeCluster
                          ? clusterIconOrange
                          : isCluster
                          ? clusterIconGreen
                          : activeMarker[0] === firstLocation.id
                          ? orangeDotIcon
                          : greenDotIcon,
                        scaledSize: new window.google.maps.Size(
                          width,
                          markerLength
                        ),
                        labelOrigin: new window.google.maps.Point(
                          centerWidth,
                          markerCenterLength
                        ),
                      }}
                      onClick={(event) =>
                        isCluster
                          ? handleClusterClick(group) // Handle cluster clicks
                          : handleMarkerClick(event, firstLocation)
                      }
                    />
                  );
                })}
              </GoogleMap>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default MapResult;
