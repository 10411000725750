import { Box, Typography } from "@mui/material";
import emptyheartIcon from "../../../assets/images/not-liked.png";
import filledheartIcon from "../../../assets/images/liked.png";
import CurrenyFormat from "components/common/CurrenyFormat";
import check from "assets/images/check.png";
import ForSale from "assets/images/for-sale.png";
import { useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
const InfoCard = ({
  pricePerDay,
  pricePerMonth,
  pricePerWeek,
  equipTitle,
  equipModal,
  equipLocation,
  thumbnail,
  isBorder,
  likeStatus,
  equipListType,
  salePrice,
  rpoAvailable,
  obo,
}) => {
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  return (
    <Box
      sx={{
        outline: isBorder ? "3px solid #faa61a" : "3px solid rgba(0,0,0,0)",
        "&:hover": {
          outline: "3px solid #faa61a",
        },
        outlineOffset: "5px",
        boxShadow: isBorder
          ? "0px 0px 10px 0px #faa61a"
          : "0 0 0 0 rgba(0,0,0,0)",
        padding: "7px",
        borderRadius: "20px",
        mb: 2,
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          borderRadius: "20px",
          backgroundColor: "#3A3A3B",
          padding: "0px",
          overflow: "hidden",
          boxSizing: "border-box", // Ensures padding and borders are included in the card size
          maxWidth: "100%", // Prevents overflow in smaller widths
        }}
      >
        {/* Image Section */}
        <Box
          sx={{
            position: "relative",
            width: { xs: "35%", sm: "200px", md: "250px", lg: "180px" },
            maxHeight: { xs: "120px", sm: "170px", md: "200px", lg: "170px" },
            flexShrink: 0,
            boxSizing: "border-box",
          }}
        >
          <Box
            component={"img"}
            src={thumbnail}
            // src="https://i.pinimg.com/474x/85/0e/d9/850ed9aa272e199ecbec87774a18dee1.jpg"
            alt=""
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px",
            }}
          />
          {equipListType === "sale" && (
            <Box
              sx={{
                position: "absolute",
                top: "-5px",
                left: "-10px",
                background: "transparent",
                zIndex: "10",
                padding: "5px 15px",
                color: "#fff",
                fontFamily: "Gotham !important",
              }}
            >
              <Box
                component={"img"}
                src={ForSale}
                alt=""
                sx={{ width: "40px", height: "auto", display: "block" }}
              />
            </Box>
          )}
          <Box
            sx={{
              backgroundColor: "#231F20",
              position: "absolute",
              right: "10px",
              bottom: "10px",
              padding: "2px 5px",
              borderRadius: "5px",
              boxSizing: "border-box",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "10px", sm: "14px", md: "16px", lg: "18px" },
                fontFamily: "GothamBold !important",
              }}
            >
              $<CurrenyFormat Currency={pricePerDay} trunc={true} />
              <Typography
                component={"span"}
                sx={{
                  color: "#faa61a",
                  fontFamily: "Gotham !important",
                  fontSize: { xs: "9px", sm: "11px", md: "12px", lg: "13px" },
                }}
              >
                /d
              </Typography>
            </Typography>
          </Box>
        </Box>

        {/* Text Section */}
        <Box
          sx={{
            flex: 1,
            padding: "10px 20px 10px 15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Typography
              sx={{
                fontFamily: "GothamBold",
                color: "#fff",
                fontSize: { xs: "12px", sm: "18px", md: "20px", lg: "20px" },
                fontWeight: "700",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {equipTitle?.slice(0, 20)}
              {equipTitle?.length > 20 ? "..." : ""}
            </Typography>

            {isLoggedin && (
              <Box
                component={"img"}
                src={likeStatus === "1" ? filledheartIcon : emptyheartIcon}
                sx={{ height: "auto", width: "25px", cursor: "pointer" }}
                className={`likeIcon ${likeStatus === "1" ? "liked" : ""}`}
              />
            )}
          </Box>
          <Typography
            sx={{
              fontFamily: "GothamBold",
              color: "#faa61a",
              fontSize: { xs: "10px", sm: "16px", md: "17px", lg: "18px" },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {equipModal?.slice(0, 30)}
            {equipModal?.length > 30 ? "..." : ""}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Gotham",
              color: "#faa61a",
              fontSize: { xs: "10px", sm: "14px", md: "14px" },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              mt: "3px",
            }}
          >
            {equipLocation?.slice(0, 45)}
            {equipLocation?.length > 45 ? "..." : ""}
          </Typography>
          {equipListType === "rent" ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                padding: "0.5rem 0",
                gap: "0",
                width: "100%",
                overflowX: "auto",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#2d2d2d",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#2d2d2d",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#2d2d2d",
                  borderRadius: "4px",
                },
                scrollbarWidth: "thin",
                scrollbarColor: "#149247 #2d2d2d",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "17px",
                      xl: "18px",
                    },
                    fontFamily: "GothamBold !important",
                  }}
                >
                  $<CurrenyFormat Currency={pricePerMonth} trunc={true} />
                  <Typography
                    component={"span"}
                    sx={{
                      color: "#faa61a",
                      fontFamily: "Gotham !important",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "12px",
                        lg: "13px",
                        xl: "14px",
                      },
                    }}
                  >
                    /m
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderLeft: "2px solid #faa61a",
                  borderRight: "2px solid #faa61a",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "17px",
                      xl: "18px",
                    },
                    fontFamily: "GothamBold !important",
                  }}
                >
                  $<CurrenyFormat Currency={pricePerWeek} trunc={true} />
                  <Typography
                    component={"span"}
                    sx={{
                      color: "#faa61a",
                      fontFamily: "Gotham !important",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "12px",
                        lg: "13px",
                        xl: "14px",
                      },
                    }}
                  >
                    /w
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "17px",
                      xl: "18px",
                    },
                    fontFamily: "GothamBold !important",
                  }}
                >
                  $<CurrenyFormat Currency={pricePerDay} trunc={true} />
                  <Typography
                    component={"span"}
                    sx={{
                      color: "#faa61a",
                      fontFamily: "Gotham !important",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "12px",
                        lg: "13px",
                        xl: "14px",
                      },
                    }}
                  >
                    /d
                  </Typography>
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              className=""
              sx={{
                padding: "5px 0",
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "0",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                {rpoAvailable === 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      margin: "0rem 0",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={check}
                      alt=""
                      style={{
                        width: "22px",
                        height: "22px",
                        marginTop: "0px",
                      }}
                    />
                    <Typography
                      sx={{
                        gap: "8px",
                        fontFamily: "Arial !important",
                        color: "#faa61a !important",
                        fontWeight: "700",
                      }}
                    >
                      RPO Avbl
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "17px",
                      xl: "18px",
                    },
                    fontFamily: "Gotham !important",
                    fontWeight: "600",
                  }}
                >
                  $<CurrenyFormat Currency={salePrice} />
                </Typography>
                &nbsp;
                {obo == 1 && (
                  <Typography
                    sx={{
                      gap: "8px",
                      fontFamily: "Arial !important",
                      color: "#faa61a !important",
                      fontWeight: "700",
                    }}
                  >
                    obo
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default InfoCard;
